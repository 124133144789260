import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

const HackNottsIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 2031.05 2518.67"
    >
      <defs></defs>
      <path d="M2030.08,17.17C2030.55.5,2013.4.5,2013.4.5H1559.58c-14.5,0-15.83,16.67-15.83,16.67l-41,475.5-269.3.18S1208,33.83,1207,17.17,1191.29.5,1191.29.5,855.42.58,839.42.54s-15.75,16.63-15.75,16.63L797.76,492.84l-274.36,0s-35-459-36.42-475.65S470.14.5,470.14.5H20.42C.5.5.5,17.17.5,17.17S7.68,957.33,8.38,980s21.7,22.1,21.7,22.1l932.28,10.39s-.89,1429.12,0,1467.4,37.39,38.28,37.39,38.28H1989.4c41.15,0,41.15-38.28,41.15-38.28S2029.62,33.83,2030.08,17.17ZM1711.45,1749.05c.58,20.14-8.87,33.85-22.17,41.21q-176.07,144.08-352,288.15c-31.85,26.05-83.81,11.34-83.81-34.82v-62.74c0-22.26-5.3-54.5,6.49-74.64,15.41-26.29,48.76-47.48,70.77-67.49q79.48-72.26,159-144.5-79.49-72.48-158.92-145c-22.17-20.21-67.25-48.26-75.84-79.43-5.8-21.11-1.51-47.5-1.51-69v-55.89c0-45.51,52.21-61.75,83.81-34.81q120.87,103,241.5,206.1l111,94.64C1721.94,1638.28,1710.35,1710.79,1711.45,1749.05Z" />
      <path d="M720.66,1471.15c5.81-21.11,1.52-47.5,1.52-69v-55.89c0-45.51-52.21-61.75-83.81-34.81q-120.87,103-241.5,206.1l-111,94.64c-32.16,27.41-20.57,99.92-21.68,138.18-.58,20.14,8.88,33.86,22.18,41.21q176.07,144.08,352,288.15c31.85,26.05,83.81,11.34,83.81-34.81v-62.75c0-22.26,5.3-54.5-6.5-74.64-15.4-26.29-48.75-47.48-70.76-67.49q-79.49-72.25-159-144.5,79.48-72.48,158.91-145C667,1530.37,712.08,1502.32,720.66,1471.15Z" />
    </svg>
  </SvgIcon>
);

export default HackNottsIcon;
